.letreiro{
    animation: move 60s linear infinite;

    width: 100%;
    cursor: pointer;
}

@keyframes move {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.letreiro:hover{
    animation-play-state: paused;
}